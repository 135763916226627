import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
const layoutProps = {};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vera Lauten was the youngest daughter of the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family`}</a>{`. She had two older brothers, `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{` and `}<a parentName="p" {...{
        "href": "/Alrik%20Lauten",
        "title": "Alrik Lauten"
      }}>{`Alrik Lauten`}</a>{`. Her father and mother were `}<a parentName="p" {...{
        "href": "/Halver%20Lauten",
        "title": "Halver Lauten"
      }}>{`Halver Lauten`}</a>{` and `}<a parentName="p" {...{
        "href": "/Elske%20Lauten",
        "title": "Elske Lauten"
      }}>{`Elske Lauten`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      